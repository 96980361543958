import React from 'react';
import characterImage from '../assets/buffalo.png';
import backgroundItem from '../assets/bg_item_trade.png';

const Card = () => {
    const { innerWidth: width, innerHeight: height } = window;
  return (
    <div
        style={{ 
            backgroundImage: `url(${backgroundItem})`, 
            backgroundSize: 'cover', 
            backgroundRepeat: 'none',
            width: width > 576 ? 400 : 180,
            height: width > 576 ? 464 : 210
        }}
    >
        <div style={{
            textAlign: 'left',
            marginTop: width > 576 ? 320 : 136,
            marginLeft: width > 576 ? 25: 10,
            color: 'white',
            fontSize: width > 576 ?  20 : 10
        }}>
            <p>Item1</p>
            <p>&nbsp;&nbsp;&nbsp;information item</p>
            <p>&nbsp;&nbsp;&nbsp;Price</p>
            <p>&nbsp;&nbsp;&nbsp;1.5 $Trau</p>
        </div>
    </div>
  );
};

export default Card;