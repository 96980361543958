import React, { useState } from 'react';
import backgroundNavBar from '../assets/bg_header.png';
import { Link } from 'react-router-dom';
import logo from '../assets/farmtrau.svg';
import './Header.css'

const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'News', path: '/news' },
    { name: 'About Us', path: '/about-us' },
];

const Header = () => {
  const [selectedMenu, setSelectedMenu] = useState('Home');
  const { innerWidth: width, innerHeight: height } = window;

  return (
    <div className="container justify-content-center" style={{
        paddingTop: 20,
    }}>
      <div
        className="row align-items-center"
        style={{
          backgroundImage: `url(${backgroundNavBar})`,
          backgroundSize: 'cover',
          borderRadius: 16,
          height: width < 576 ? 70 : 93,
          border: 'solid 2px',
          marginLeft: 2,
            marginRight: 2,
        }}
      >
        
            <div className='col-3 col-md-2'>
                <img src={logo} alt="Farm $TRAU"/>
            </div>
            <nav className='col-6 col-md-8'>
                    <ul className="flex text-white" >
                        {menuItems.map((item) => (
                        <li
                            style={{
                                textAlign: 'center',
                                marginRight: 12
                            }}
                            key={item.name}
                        >
                            <Link
                                to={item.path}
                                className={`rounded cursor-pointer ${selectedMenu === item.name ? 'menu-selected' : ''}`}
                                style={{
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    fontSize: width <= 576 ? 9 : 24
                                }}
                                onClick={() => setSelectedMenu(item.name)}
                            >
                                {item.name}
                            </Link>
                        </li>
                        ))}
                    </ul>
                
            </nav>
            <div className='col-3 col-md-2 '>
                <div className='row justify-content-end'>

                    <button
                        className="text-white rounded"
                        onClick={() => {
                            window.open("https://game.farmtrau.com", "_blank")
                        }}
                        style={{ 
                            paddingLeft: 16,
                            paddingRight: 16,
                            marginRight: 16,
                            flexShrink: 0,
                            minHeight: 28,
                            maxWidth: 100,
                            fontSize: width <= 576 ? 10 : 24,
                            borderRadius: '0.4375rem',
                            border: '2px solid #4E0000',
                            background: '#F00',
                        }}
                        >
                        Play
                    </button>
                </div>
            </div>
      </div>
    </div>
  );
};

export default Header;
