import React, { useState } from 'react';
import questContent from '../assets/quest_content.png';
import btnBg from '../assets/btn_bg.svg';
import bgQuest2 from '../assets/bg_quest_2.png';
import './Quest.css'

const Quest = () => {
    const { innerWidth: width, innerHeight: height } = window;
  return (
    <div className="flex justify-center" style={{
        padding: 50,
        flexDirection: 'column',
        textAlign: 'center',
    }}>
        <div style={{
            fontSize: width > 576 ? '3rem' : '1rem',
        }}>
            <span style={{
                color: '#F90',
                textAlign: 'center',
                fontFamily: 'Poetsen One',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
            }}>Quest</span>
        </div>
        <p style={{
            color: 'white',
            marginBottom: 10
        }}>Complete Missions To Receive Rewards</p>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        }}>
            <img src={questContent}/>
        </div>
        <p style={{
            color: 'white',
            fontSize: width > 576 ? '3rem' : '1rem',
            marginBottom: 20,
        }}>Click to complete now</p>
        <div style={{
            
        }}>
             <button className='text-outline-1' 
                style={{
                    marginTop: 30,
                    marginBottom: 30,
                    backgroundImage: 'linear-gradient(0deg, #2EAA2C, #EBFF00)',
                    alignSelf: 'center',
                    borderRadius: 8,
                    border: 'solid 2px #000',
                    width: width > 576 ? 200 : 120, // Đặt kích thước cố định bằng kích thước ảnh nền
                    height: width > 576 ? 70 : 38, // Đặt kích thước cố định bằng kích thước ảnh nền
                    backgroundSize: 'contain',
                    color: '#FAFF00',
                    fontSize: width > 576 ? '2rem' : '1rem'
                }}>
                    Quest
            </button>
        </div>

        {/* <div style={{
            marginTop: 50,
            alignSelf: 'center',
            display: 'flex',
            width: '90rem',
            height: '7.0625rem',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0,
            borderRadius: '12.375rem',
            background: `url(${bgQuest2})`
        }}>

        </div> */}
    </div>
  );
};

export default Quest;
