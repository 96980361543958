import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import bgItemCreative from '../assets/bg_item_creative.png';
import Card from './Card';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './SliderTradeItem.css';

// import required modules
import { Pagination } from 'swiper/modules';

export default function SliderTradeItem() {
  return (
    <>
      <Swiper
            slidesPerView={'auto'}
            centeredSlides={true}
            spaceBetween={8}
            pagination={{
                clickable: true,
            }}
            modules={[Pagination]}
            initialSlide={1}
        >
            <SwiperSlide><Card/></SwiperSlide>
            <SwiperSlide><Card/></SwiperSlide>
            <SwiperSlide><Card/></SwiperSlide>
        </Swiper>
    </>
  );
}