


import React from 'react';
import bottomBg from '../assets/bottom_bg.jpg';

const Footer = () => {
    const { innerWidth: width, innerHeight: height } = window;
    if (width > 576) {
        return (
            <section 
                style={{ 
                    backgroundImage: `url(${bottomBg})`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    justifyContent: 'center',
                    minHeight: 372, 
                    paddingTop: 100,
                    paddingBottom: 20,
              }}>
                 <div className="text-white py-8">
                  <div className="container mx-auto flex justify-between items-center">
                    <div className="flex items-center">
                      {/* <img src={logo} alt="Logo" className="h-8 mr-3" /> */}
                      <span className="text-lg">LOGOIPSUM</span>
                    </div>
                    <div className="flex space-x-16">
                      <div>
                        <h5 className="font-bold mb-2">INFO</h5>
                        <ul>
                          <li><a href="#" className="hover:text-yellow-500">Catalog</a></li>
                          <li><a href="#" className="text-yellow-500">Sale</a></li>
                          <li><a href="#" className="hover:text-yellow-500">About us</a></li>
                          <li><a href="#" className="hover:text-yellow-500">For customers</a></li>
                          <li><a href="#" className="hover:text-yellow-500">Contacts</a></li>
                        </ul>
                      </div>
                      <div>
                        <h5 className="font-bold mb-2">CONTACT US</h5>
                        <p>+1 980 971-24-19</p>
                        <p>hello@logoipsum.com</p>
                      </div>
                      <div>
                        <h5 className="font-bold mb-2">FIND US</h5>
                        <p>1901 Thornridge Cir. Shiloh,</p>
                        <p>Hawaii 81063</p>
                        <p>Everyday from 10 am to 8 pm</p>
                      </div>
                    </div>
                  </div>
                </div>
            </section>
        )
    } else {
        return (
            <section 
                className='container'
                style={{ 
                    backgroundImage: `url(${bottomBg})`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    justifyContent: 'center',
                    minHeight: 372, 
                    paddingTop: 100
              }}>
                <div className='row'>
                    <div className="col col-6">
                    {/* <img src={logo} alt="Logo" className="h-8 mr-3" /> */}
                        <span className="text-lg">LOGOIPSUM</span>
                    </div>
                    <div className="col col-6">
                        <h5 className="font-bold mb-2">INFO</h5>
                        <ul>
                            <li><a href="#" className="hover:text-yellow-500">Catalog</a></li>
                            <li><a href="#" className="text-yellow-500">Sale</a></li>
                            <li><a href="#" className="hover:text-yellow-500">About us</a></li>
                            <li><a href="#" className="hover:text-yellow-500">For customers</a></li>
                            <li><a href="#" className="hover:text-yellow-500">Contacts</a></li>
                        </ul>
                    </div>
                </div>
                <div className='row' style={{height: 48}}></div>
                <div className="row">
                    
                    <div className='col col-6'>
                        <h5 className="font-bold mb-2">CONTACT US</h5>
                        <p>+1 980 971-24-19</p>
                        <p>hello@logoipsum.com</p>
                    </div>
                    <div className='col col-6'>
                        <h5 className="font-bold mb-2">FIND US</h5>
                        <p>1901 Thornridge Cir. Shiloh,</p>
                        <p>Hawaii 81063</p>
                        <p>Everyday from 10 am to 8 pm</p>
                    </div>
                </div>
            </section>
        )
    }
};

export default Footer;
