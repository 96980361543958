import React from 'react';
import background from '../assets/background_1.jpg';
import bottomBg from '../assets/bottom_bg.jpg';

const News = () => {
  return (
    
        <div 
            className='container'
            style={{
                width: '100%',
                minHeight: '200vh',
                justifyContent: 'center',
                display: 'flex',
            }}
        >
            <div 
                style={{
                    textAlign: 'justify',
                    top: 200,
                    borderRadius: 8,
                    background: '#CB6F2A',
                    padding: 16,
                    marginTop: 16,
            }}>
                <div style={{
                    background: '#FFF2D4',
                    borderRadius: 8,
                    border: 'solid 4px',
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                    paddingBottom: 16,
                }}>
                    <p style={{
                        fontSize: '1rem',
                        color: 'white',
                        textShadow: '0 0 2px #000',
                        marginBottom: 32
                    }}>Explore, Creative Creative & Sell Your NFTs On Art.In</p>
                    <p style={{
                        color: 'white',
                        textShadow: '0 0 2px #000',
                    }}>In the heart of the Whispering Forest, where ancient oaks stood tall and their gnarled roots wove intricate patterns into the earth, there existed a secret known only to a select few. The villagers spoke of it in hushed tones, their eyes darting nervously toward the dense canopy above. They called it the Veil—a shimmering curtain that separated their world from another, a realm of magic and mystery.
            Evelyn, a young botanist with wild curls and ink-stained fingers, had always been drawn to the forest. She spent her days cataloging rare ferns and deciphering the language of leaves. But it was at night, when the moon hung low and the air hummed with unseen energy, that she felt the pull most strongly. The Veil beckoned her—an invitation to step beyond the mundane and into the extraordinary.
            One moonless night, Evelyn followed an ancient deer trail deeper into the forest. The trees leaned in, their branches forming a natural archway. She stepped through, and suddenly, the world shifted. Colors intensified—the moss underfoot glowed emerald, and the distant stars pulsed like distant heartbeats. She had crossed the Veil.
            Here, the laws of nature bent and twisted. Creatures of myth roamed freely: luminescent sprites flitted through the air, their laughter like tinkling wind chimes; will-o’-the-wisps danced along the edges of forgotten ponds, leading lost souls astray. And at the heart of it all stood the Whispering Tree—an ancient oak with bark etched in cryptic symbols.
            The tree spoke to Evelyn in dreams. Its voice was a melody of rustling leaves and distant echoes. It revealed secrets—the origins of the Veil, the lost city of Elowen hidden beneath the forest floor, and the prophecy that bound their worlds together. Evelyn listened, her notebook filling with sketches and half-formed theories.
            But the Whispering Tree had enemies. The Shadowkin—a race of shadow-dwellers—sought to tear down the Veil and plunge both realms into chaos. They whispered curses in forgotten tongues, their eyes like shards of obsidian. Evelyn knew she had to act. She gathered allies—a mischievous sprite named Thistle, a brooding centaur named Aric, and a reclusive sorceress named Elowyn.
            Together, they embarked on a quest to unravel the prophecy. Clues led them through moonlit glades, across treacherous swamps, and into forgotten libraries. They deciphered riddles, battled enchanted beasts, and faced their deepest fears. Along the way, Evelyn discovered her own lineage—a bloodline tied to the Whispering Tree itself.
            As the final confrontation loomed, Evelyn stood before the Veil, her heart pounding. The Shadowkin amassed on the other side, their leader—a creature of shifting shadows—ready to tear the fabric of reality apart. But Evelyn had a choice: to close the Veil forever or to mend it, preserving the delicate balance between worlds.
            She raised her hands, the ancient symbols glowing on her skin. The Whispering Tree’s voice echoed in her mind: “The choice is yours, child of both realms.”
            And so, with tears in her eyes, Evelyn whispered the incantation—the Veil shimmered, wavered, and then sealed shut. The forest sighed, and the worlds settled back into their rightful places. Evelyn returned to her village, her notebook filled with memories and unanswered questions.
            But sometimes, on moonless nights, she would stand by the Whispering Tree, listening to its rustling leaves. And she wondered if the Veil truly closed or merely shifted, waiting for another curious soul to step through and unravel the enigma once more.
            I hope you enjoyed this mystical tale! If you’d like more stories or have any other requests, feel free to ask. 🌿🌙✨
            In the heart of the Whispering Forest, where ancient oaks stood tall and their gnarled roots wove intricate patterns into the earth, there existed a secret known only to a select few. The villagers spoke of it in hushed tones, their eyes darting nervously toward the dense canopy above. They called it the Veil—a shimmering curtain that separated their world from another, a realm of magic and mystery.
            Evelyn, a young botanist with wild curls and ink-stained fingers, had always been drawn to the forest. She spent her days cataloging rare ferns and deciphering the language of leaves. But it was at night, when the moon hung low and the air hummed with unseen energy, that she felt the pull most strongly. The Veil beckoned her—an invitation to step beyond the mundane and into the extraordinary.
            One moonless night, Evelyn followed an ancient deer trail deeper into the forest. The trees leaned in, their branches forming a natural archway. She stepped through, and suddenly, the world shifted. Colors intensified—the moss underfoot glowed emerald, and the distant stars pulsed like distant heartbeats. She had crossed the Veil.
            Here, the laws of nature bent and twisted. Creatures of myth roamed freely: luminescent sprites flitted through the air, their laughter like tinkling wind chimes; will-o’-the-wisps danced along the edges of forgotten ponds, leading lost souls astray. And at the heart of it all stood the Whispering Tree—an ancient oak with bark etched in cryptic symbols.
            The tree spoke to Evelyn in dreams. Its voice was a melody of rustling leaves and distant echoes. It revealed secrets—the origins of the Veil, the lost city of Elowen hidden beneath the forest floor, and the prophecy that bound their worlds together. Evelyn listened, her notebook filling with sketches and half-formed theories.
            But the Whispering Tree had enemies. The Shadowkin—a race of shadow-dwellers—sought to tear down the Veil and plunge both realms into chaos. They whispered curses in forgotten tongues, their eyes like shards of obsidian. Evelyn knew she had to act. She gathered allies—a mischievous sprite named Thistle, a brooding centaur named Aric, and a reclusive sorceress named Elowyn.
            Together, they embarked on a quest to unravel the prophecy. Clues led them through moonlit glades, across treacherous swamps, and into forgotten libraries. They deciphered riddles, battled enchanted beasts, and faced their deepest fears. Along the way, Evelyn discovered her own lineage—a bloodline tied to the Whispering Tree itself.
            As the final confrontation loomed, Evelyn stood before the Veil, her heart pounding. The Shadowkin amassed on the other side, their leader—a creature of shifting shadows—ready to tear the fabric of reality apart. But Evelyn had a choice: to close the Veil forever or to mend it, preserving the delicate balance between worlds.
            She raised her hands, the ancient symbols glowing on her skin. The Whispering Tree’s voice echoed in her mind: “The choice is yours, child of both realms.”
            And so, with tears in her eyes, Evelyn whispered the incantation—the Veil shimmered, wavered, and then sealed shut. The forest sighed, and the worlds settled back into their rightful places. Evelyn returned to her village, her notebook filled with memories and unanswered questions.
            But sometimes, on moonless nights, she would stand by the Whispering Tree, listening to its rustling leaves. And she wondered if the Veil truly closed or merely shifted, waiting for another curious soul to step through and unravel the enigma once more.
            I hope you enjoyed this mystical tale! If you’d like more stories or have any other requests, feel free to ask. 🌿🌙✨In the heart of the Whispering Forest, where ancient oaks stood tall and their gnarled roots wove intricate patterns into the earth, there existed a secret known only to a select few. The villagers spoke of it in hushed tones, their eyes darting nervously toward the dense canopy above. They called it the Veil—a shimmering curtain that separated their world from another, a realm of magic and mystery.
            Evelyn, a young botanist with wild curls and ink-stained fingers, had always been drawn to the forest. She spent her days cataloging rare ferns and deciphering the language of leaves. But it was at night, when the moon hung low and the air hummed with unseen energy, that she felt the pull most strongly. The Veil beckoned her—an invitation to step beyond the mundane and into the extraordinary.
            One moonless night, Evelyn followed an ancient deer trail deeper into the forest. The trees leaned in, their branches forming a natural archway. She stepped through, and suddenly, the world shifted. Colors intensified—the moss underfoot glowed emerald, and the distant stars pulsed like distant heartbeats. She had crossed the Veil.
            Here, the laws of nature bent and twisted. Creatures of myth roamed freely: luminescent sprites flitted through the air, their laughter like tinkling wind chimes; will-o’-the-wisps danced along the edges of forgotten ponds, leading lost souls astray. And at the heart of it all stood the Whispering Tree—an ancient oak with bark etched in cryptic symbols.
            The tree spoke to Evelyn in dreams. Its voice was a melody of rustling leaves and distant echoes. It revealed secrets—the origins of the Veil, the lost city of Elowen hidden beneath the forest floor, and the prophecy that bound their worlds together. Evelyn listened, her notebook filling with sketches and half-formed theories.
            But the Whispering Tree had enemies. The Shadowkin—a race of shadow-dwellers—sought to tear down the Veil and plunge both realms into chaos. They whispered curses in forgotten tongues, their eyes like shards of obsidian. Evelyn knew she had to act. She gathered allies—a mischievous sprite named Thistle, a brooding centaur named Aric, and a reclusive sorceress named Elowyn.
            Together, they embarked on a quest to unravel the prophecy. Clues led them through moonlit glades, across treacherous swamps, and into forgotten libraries. They deciphered riddles, battled enchanted beasts, and faced their deepest fears. Along the way, Evelyn discovered her own lineage—a bloodline tied to the Whispering Tree itself.
            As the final confrontation loomed, Evelyn stood before the Veil, her heart pounding. The Shadowkin amassed on the other side, their leader—a creature of shifting shadows—ready to tear the fabric of reality apart. But Evelyn had a choice: to close the Veil forever or to mend it, preserving the delicate balance between worlds.
            She raised her hands, the ancient symbols glowing on her skin. The Whispering Tree’s voice echoed in her mind: “The choice is yours, child of both realms.”
            And so, with tears in her eyes, Evelyn whispered the incantation—the Veil shimmered, wavered, and then sealed shut. The forest sighed, and the worlds settled back into their rightful places. Evelyn returned to her village, her notebook filled with memories and unanswered questions.
            But sometimes, on moonless nights, she would stand by the Whispering Tree, listening to its rustling leaves. And she wondered if the Veil truly closed or merely shifted, waiting for another curious soul to step through and unravel the enigma once more.
            I hope you enjoyed this mystical tale! If you’d like more stories or have any other requests, feel free to ask. 🌿🌙✨</p>
                </div>
                
            </div>
        
        </div>
  );
};

export default News;
