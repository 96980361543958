import React, { useState } from 'react';
import btnBg from '../assets/btn_bg.svg';
import SliderTradeItem from './SliderTradeItem';
import './TradeItem.css';

const TradeItem = () => {
    const { innerWidth: width, innerHeight: height } = window;
  return (
    <div className="flex justify-center" style={{
        flexDirection: 'column',
        textAlign: 'center',
        marginTop: width > 576 ? 50 : 0,
    }}>
        <div style={{
            fontSize: '2rem',
            textAlign: 'center'
        }}>
            <span style={{
                color: '#F90',
                textAlign: 'center',
                fontFamily: 'Poetsen One',
                fontSize: width > 576 ? '3rem' : '1rem',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
            }}>Trade,&nbsp;</span>
            <span style={{
                color: 'white',
                textAlign: 'center',
                fontFamily: 'Poetsen One',
                fontSize: width > 576 ? '3rem' : '1rem',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
            }}>Exchange Items</span>
        </div>
        <p style={{
            color: 'white',
        
        }}>Sell items that you have and bring benefits</p>
        <br/>
        <SliderTradeItem/>
        <div style={{
            
        }}>
             <button className='text-outline-1' 
                style={{
                    marginTop: 30,
                    marginBottom: 30,
                    backgroundImage: 'linear-gradient(0deg, #2EAA2C, #EBFF00)',
                    alignSelf: 'center',
                    borderRadius: 8,
                    border: 'solid 2px #000',
                    width: width > 576 ? 200 : 120, // Đặt kích thước cố định bằng kích thước ảnh nền
                    height: width > 576 ? 70 : 38, // Đặt kích thước cố định bằng kích thước ảnh nền
                    backgroundSize: 'contain',
                    color: '#FAFF00',
                    fontSize: width > 576 ? '2rem' : '1rem'
                }}>
                    More
            </button>
        </div>
       
    </div>
  );
};

export default TradeItem;
