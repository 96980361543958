import React from 'react';
import Header from './components/Header';
import TradeItem from './components/TradeItem';
import MainContent from './components/MainContent';
import background from './assets/background_1.jpg';
import bg_h3 from './assets/bg_h3.png';

import Quest from './components/Quest';
import Footer from './components/Footer';

function App() {
  return (
    <div className="min-h-screen" style={{ 
    }}>
      <div className='container-fluid' style={{padding: 0}}>
        <section 
          className='row'
          style={{ 
            justifyContent: 'center'
        }}>
          <MainContent />
        </section>
        <section style={{ 
          backgroundImage: `url(${bg_h3})`, 
          backgroundSize: 'contain', 
          backgroundPosition: 'center', 
          justifyContent: 'center'
        }}>
          <div className='row' style={{height: 10}}></div>
          <TradeItem />
          <div className='row' style={{height: 20}}></div>
        </section>
        <section style={{ 
          backgroundImage: `url(${bg_h3})`, 
          backgroundSize: 'contain', 
          backgroundPosition: 'center', 
          justifyContent: 'center'
        }}>
          <Quest />
        </section>
        <div style={{
            position: 'absolute',
            width: '100%',
            left: 0,
        }}>
            <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
