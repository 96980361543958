import React, { useState } from 'react';
import Character from '../assets/buffalo.png';
import backgroundButtonPlayNow from '../assets/playnow.png';
import item_bg from '../assets/background_item.png';
import slide1 from '../assets/slide1.png';
import slide2 from '../assets/slide2.png';
import logo from '../assets/logo.png';


import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './MainContent.css'


// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
import Card from './Card';
import SliderItem from './SliderItem';

const cardsData = [
    { id: 1, content: <Card /> },
    { id: 2, content: <Card /> },
    { id: 3, content: <Card /> },
    { id: 4, content: <Card /> },
];

const MainContent = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return (
        <div
            className="container"
            style={{}}
        >
            <div
                className='row'
            >
                <img 
                    src={logo} 
                    alt="Farm $TRAU" 
                    className="my-4" 
                    style={{
                        width: 'auto',
                        marginTop: 80,
                        marginBottom: 80,
                        margin: 'auto',
                        height: width > 576 ? 300 : 120
                }}/>
            </div>
            <div className='row'>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 20,
                        stretch: -100,
                        depth: 1200,
                        modifier: 1,
                        slideShadows: false,
                    }}

                    initialSlide={2}
                    modules={[EffectCoverflow, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src={slide2} alt='item1'/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slide1} alt='item2'/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <div style={{
                                position: 'relative',
                            }}>
                                <img src={item_bg} />
                                <img src={Character} 
                                    alt=''
                                    style={{
                                        position: 'absolute',
                                        top: 40,
                                        width: width > 576 ? 300 : 100,
                                        left: 50
                                    }}/>
                                <span 
                                    style={{
                                        position: 'absolute',
                                        top: width > 576 ? 370 : 185,
                                        marginLeft: 10,
                                        marginRight: 10,
                                        textAlign: 'center',
                                        color: 'white',
                                        fontSize: width > 576 ? 20 : 10,
                                    }}
                                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                            </div>

                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slide1} alt='item3'/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slide2} alt='item4'/>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='row' style={{
                marginTop: width > 576 ? 70 : 0,
                marginBottom: width > 576 ? 300 : 0
            }}>
                <button
                    className="font-bold rounded mt-4"
                    style={{ 
                        margin: 'auto',
                        backgroundImage: `url(${backgroundButtonPlayNow})`,
                        width: width > 576 ? '300px' : '200px', // Đặt kích thước cố định bằng kích thước ảnh nền
                        height: width > 576 ? '90px' : '60px', // Đặt kích thước cố định bằng kích thước ảnh nền
                        backgroundSize: 'cover',
                    }}
                >
                </button>
            </div>
            
            <div className='row'>
                <div style={{
                    marginTop: width > 576 ? 50 : 150,
                    marginBottom: width > 576 ? 100 : 0,
                    fontSize: '2rem',
                    textAlign: 'center'
                }}>
                    <span style={{
                        color: '#F90',
                        textAlign: 'center',
                        fontFamily: 'Poetsen One',
                        fontSize: width > 576 ? '3rem' : '1rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                    }}>Explore,&nbsp;</span>
                    <span style={{
                        color: 'white',
                        textAlign: 'center',
                        fontFamily: 'Poetsen One',
                        fontSize: width > 576 ? '3rem' : '1rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                    }}>Creative Creative & Sell</span>
                    <p style={{
                        color: 'white',
                        textAlign: 'center',
                        fontFamily: 'Poetsen One',
                        fontSize: width > 576 ? '3rem' : '1rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                    }}>Your NFTs On Art.In</p>
                    <div style={{
                        width: '100%',
                        height: 40,
                    }}></div>
                    <SliderItem />
                </div>
            </div>
            <div className='row'>
            </div>
            
        </div>
    );
};

export default MainContent;
