import React from 'react';

import logo from '../assets/logo.png';
import bg_about_us from '../assets/bg_all.jpg';

import './AboutUs.css'
import Footer from './Footer';

const AboutUs = () => {
  return (
    <div 
        className='container-fluid'
        style={{

            justifyContent: 'center',
            display: 'flex',
    }}>
        <div
            className='container'
            style={{
                justifyContent: 'center'
            }}
        >
            <div className='row justify-content-center' style={{marginTop: 30}}>
                <div
                    className='col col-8'
                >
                    <img 
                        alt='logo'
                        title='logo'
                        src={logo} 
                        style={{
                            margin: 'auto'
                        }}></img>
                </div>
            </div>
            <div
                className='row' 
                style={{
                    marginTop: 16
                }}
                >
                <div 
                    className='col col-12'
                    style={{
                        background: `url(${bg_about_us})`,
                        minHeight: 142,
                        backgroundSize: 'cover',
                        borderRadius: 8,
                        border: 'solid 2px',
                        textAlign: 'center',
                        padding: 16
                    }}
                >
                    <span className='title-box'>About Us</span>
                    <div
                        style={{
                            marginTop: 16,
                            backgroundColor: '#FFF2D4',
                            borderRadius: 8,
                            border: 'solid 1px',
                            textAlign: 'justify',
                            padding: 8,
                            color: 'white',
                            textShadow: '0 0 2px #000'
                        }}
                    >
                        <span>[Name of NFT game company] is a pioneer in the field of NFT game development, bringing unique and engaging gaming experiences on the blockchain platform. We were founded by a team passionate about gaming and blockchain technology, with the goal of creating a decentralized gaming world where players can own in-game assets and earn income from their hobby</span>
                    </div>
                </div>
            </div>
            <div
                className='row' 
                style={{
                    marginTop: 16
                }}
                >
                <div 
                    className='col col-12'
                    style={{
                        background: `url(${bg_about_us})`,
                        minHeight: 142,
                        backgroundSize: 'cover',
                        borderRadius: 8,
                        border: 'solid 2px',
                        textAlign: 'center',
                        padding: 16
                    }}
                >
                    <span className='title-box'>Mission and Our core values</span>
                    <div
                        style={{
                            marginTop: 16,
                            backgroundColor: '#FFF2D4',
                            borderRadius: 8,
                            border: 'solid 1px',
                            textAlign: 'justify',
                            padding: 8,
                            color: 'white',
                            textShadow: '0 0 2px #000'
                        }}
                    >
                        <p>Create innovative and fun NFT games that attract players around the world.
Build a secure and transparent blockchain platform, providing a trustworthy gaming experience.
Grow a strong community of NFT gamers where everyone can interact, learn, and profit.</p>
                        <br></br>
                        <p>Innovation: We are constantly creating and developing new and unique NFT games.
Quality: We are committed to delivering high-quality, smooth and engaging gaming experiences.
Community: We value the gaming community and always listen to their feedback.</p>
                        <p>

                        </p>
                    </div>
                </div>
            </div>

            <div
                className='row' 
                style={{
                    marginTop: 16
                }}
                >
                <div 
                    className='col col-12'
                    style={{
                        background: `url(${bg_about_us})`,
                        minHeight: 142,
                        backgroundSize: 'cover',
                        borderRadius: 8,
                        border: 'solid 2px',
                        textAlign: 'center',
                        padding: 16
                    }}
                >
                    <span className='title-box'>Development Plan and Action</span>
                    <div
                        style={{
                            marginTop: 16,
                            backgroundColor: '#FFF2D4',
                            borderRadius: 8,
                            border: 'solid 1px',
                            textAlign: 'justify',
                            padding: 8,
                            color: 'white',
                            textShadow: '0 0 2px #000'
                        }}
                    >
                        <p>[Short-term development plan]: Brief description of the company's development plan in the short term, including launching new NFT games, expanding the platform, and attracting more users.</p>
                        <p>[Long-term development plan]: Brief description of the company's long-term development plan, including building a complete NFT game ecosystem and becoming a leading company in this field</p>

                        <ul style={{
                            listStyleType: 'square',
                            marginLeft: 16
                        }}>
                            <li>Join the [NFT Game Company Name] community: Invite readers to join the company's community on social networks to update the latest information and connect with other players.</li>
                            <li>Experience our NFT games: Readers are invited to download and play the company's NFT games to experience the fun and excitement firsthand.</li>
                            <li>Become an investor: Invite readers to invest in the company's project to become part of the community</li>
                        </ul>
                        <p>tuneshare</p>
                        <p>more_vert</p>

                    </div>
                </div>
            </div>
            <div className='row' style={{height: 48}}></div>
            <div style={{
                position: 'absolute',
                width: '100%',
                left: 0,
            }}>
                <Footer />
            </div>
        </div>
        
    </div>
  );
};

export default AboutUs;
