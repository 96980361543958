import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Header from './components/Header';
import MainContent from './components/MainContent';
import background from './assets/background_1.jpg';
import background_mobi from './assets/bg_mobi.png';
import News from './components/News';
import AboutUs from './components/AboutUs';
import reportWebVitals from './reportWebVitals';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const { innerWidth: width, innerHeight: height } = window;
root.render(
  <React.StrictMode>
    <Router>
      <div style={{
        width: '100%',
        justifyContent: 'center',
        background: `url(${width > 576 ? background : background_mobi})`,
          backgroundRepeat: 'no-repeat',
          // backgroundAttachment: 'fixed',
          backgroundSize: '100% auto',
          backgroundPosition: 'center top',
      }}>
        <Header />
        <div>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/news" element={<News />} />
            <Route path="/about-us" element={<AboutUs />} />
          </Routes>
        </div>
      </div>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
